import { api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    listAssigneeUsers: build.query<
      ListAssigneeUsersApiResponse,
      ListAssigneeUsersApiArg
    >({
      query: () => ({ url: `/users` }),
    }),
    updateInvoicingMethod: build.mutation<
      UpdateInvoicingMethodApiResponse,
      UpdateInvoicingMethodApiArg
    >({
      query: (queryArg) => ({
        url: `/subscriptions/${queryArg.id}/invoicing-method`,
        method: 'PUT',
        body: queryArg.updateInvoicingMethodRequest,
      }),
    }),
    allowInvoicingSnapshot: build.mutation<
      AllowInvoicingSnapshotApiResponse,
      AllowInvoicingSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/allow-invoicing`,
        method: 'POST',
      }),
    }),
    assignSnapshotUser: build.mutation<
      AssignSnapshotUserApiResponse,
      AssignSnapshotUserApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/assigned-user`,
        method: 'PUT',
        body: queryArg.assignSnapshotUserRequest,
      }),
    }),
    copySnapshot: build.mutation<CopySnapshotApiResponse, CopySnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/copy`,
        method: 'POST',
        body: queryArg.copySnapshotRequest,
      }),
    }),
    createSnapshotFromBaseline: build.mutation<
      CreateSnapshotFromBaselineApiResponse,
      CreateSnapshotFromBaselineApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/create`,
        method: 'POST',
        body: queryArg.createSnapshotFromBaselineRequest,
      }),
    }),
    creditSnapshot: build.mutation<CreditSnapshotApiResponse, CreditSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/credit`,
        method: 'POST',
      }),
    }),
    disallowInvoicingSnapshot: build.mutation<
      DisallowInvoicingSnapshotApiResponse,
      DisallowInvoicingSnapshotApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/disallow-invoicing`,
        method: 'POST',
      }),
    }),
    dismissSnapshotMessage: build.mutation<
      DismissSnapshotMessageApiResponse,
      DismissSnapshotMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/messages/${queryArg.messageId}/dismiss`,
        method: 'POST',
      }),
    }),
    exportSnapshot: build.mutation<ExportSnapshotApiResponse, ExportSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/export`,
        method: 'POST',
      }),
    }),
    getSnapshot: build.query<GetSnapshotApiResponse, GetSnapshotApiArg>({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}` }),
    }),
    getSnapshotTotals: build.query<
      GetSnapshotTotalsApiResponse,
      GetSnapshotTotalsApiArg
    >({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}/totals` }),
    }),
    listRelatedSnapshots: build.query<
      ListRelatedSnapshotsApiResponse,
      ListRelatedSnapshotsApiArg
    >({
      query: (queryArg) => ({ url: `/baselines/${queryArg.id}/snapshots` }),
    }),
    listSnapshotCollections: build.query<
      ListSnapshotCollectionsApiResponse,
      ListSnapshotCollectionsApiArg
    >({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}/collections` }),
    }),
    listSnapshotEforms: build.query<
      ListSnapshotEformsApiResponse,
      ListSnapshotEformsApiArg
    >({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}/eforms` }),
    }),
    listSnapshotEvents: build.query<
      ListSnapshotEventsApiResponse,
      ListSnapshotEventsApiArg
    >({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}/events` }),
    }),
    listSnapshotStandards: build.query<
      ListSnapshotStandardsApiResponse,
      ListSnapshotStandardsApiArg
    >({
      query: (queryArg) => ({ url: `/snapshots/${queryArg.id}/standards` }),
    }),
    lockSnapshot: build.mutation<LockSnapshotApiResponse, LockSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/lock`,
        method: 'POST',
      }),
    }),
    rebaseSnapshot: build.mutation<RebaseSnapshotApiResponse, RebaseSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/rebase`,
        method: 'POST',
        body: queryArg.rebaseSnapshotRequest,
      }),
    }),
    removeSnapshotItems: build.mutation<
      RemoveSnapshotItemsApiResponse,
      RemoveSnapshotItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/remove-items`,
        method: 'POST',
        body: queryArg.removeSnapshotPricesRequest,
      }),
    }),
    revertSnapshotPrices: build.mutation<
      RevertSnapshotPricesApiResponse,
      RevertSnapshotPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/revert-prices`,
        method: 'POST',
        body: queryArg.revertSnapshotPricesRequest,
      }),
    }),
    setContactPerson: build.mutation<
      SetContactPersonApiResponse,
      SetContactPersonApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/contact-person`,
        method: 'PUT',
        body: queryArg.setContactPersonRequest,
      }),
    }),
    startSnapshot: build.mutation<StartSnapshotApiResponse, StartSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/start`,
        method: 'POST',
      }),
    }),
    submitSnapshot: build.mutation<SubmitSnapshotApiResponse, SubmitSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/submit`,
        method: 'POST',
      }),
    }),
    unlockSnapshot: build.mutation<UnlockSnapshotApiResponse, UnlockSnapshotApiArg>({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/unlock`,
        method: 'POST',
      }),
    }),
    updatePurchaseOrderNumber: build.mutation<
      UpdatePurchaseOrderNumberApiResponse,
      UpdatePurchaseOrderNumberApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/purchase-order-number`,
        method: 'PUT',
        body: queryArg.updatePurchaseOrderNumberRequest,
      }),
    }),
    updateSnapshotPrices: build.mutation<
      UpdateSnapshotPricesApiResponse,
      UpdateSnapshotPricesApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/update-prices`,
        method: 'POST',
        body: queryArg.updateSnapshotPricesRequest,
      }),
    }),
    importRequestForPriceItems: build.mutation<
      ImportRequestForPriceItemsApiResponse,
      ImportRequestForPriceItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/import`,
        method: 'POST',
        body: queryArg.importRequestForPriceItemsRequest,
      }),
    }),
    exportRequestForPriceItems: build.mutation<
      ExportRequestForPriceItemsApiResponse,
      ExportRequestForPriceItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/export`,
        method: 'POST',
        params: { snapshotId: queryArg.snapshotId },
      }),
    }),
    updateSnapshotDiscounts: build.mutation<
      UpdateSnapshotDiscountsApiResponse,
      UpdateSnapshotDiscountsApiArg
    >({
      query: (queryArg) => ({
        url: `/snapshots/${queryArg.id}/discounts`,
        method: 'PUT',
        body: queryArg.updateSnapshotDiscountsRequest,
      }),
    }),
    listCustomerContactPersons: build.query<
      ListCustomerContactPersonsApiResponse,
      ListCustomerContactPersonsApiArg
    >({
      query: (queryArg) => ({
        url: `/customers/${queryArg.customerId}/contact-persons`,
      }),
    }),
    bulkRequestBaselineUpdate: build.mutation<
      BulkRequestBaselineUpdateApiResponse,
      BulkRequestBaselineUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/baselines/request-updates`,
        method: 'POST',
        body: queryArg.bulkRequestBaselineUpdateRequest,
      }),
    }),
    exportBaselines: build.query<ExportBaselinesApiResponse, ExportBaselinesApiArg>({
      query: (queryArg) => ({
        url: `/baselines/export`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    listBaselines: build.query<ListBaselinesApiResponse, ListBaselinesApiArg>({
      query: (queryArg) => ({
        url: `/baselines`,
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    requestBaselineUpdate: build.mutation<
      RequestBaselineUpdateApiResponse,
      RequestBaselineUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/baselines/${queryArg.id}/request-update`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as generatedApi };
export type ListAssigneeUsersApiResponse =
  /** status 200 List of users */ UserModel[];
export type ListAssigneeUsersApiArg = void;
export type UpdateInvoicingMethodApiResponse = /** status 200 Success */ void;
export type UpdateInvoicingMethodApiArg = {
  /** Subscription identifier */
  id: string;
  /** Request body */
  updateInvoicingMethodRequest: UpdateInvoicingMethodRequest;
};
export type AllowInvoicingSnapshotApiResponse = /** status 200 Success */ void;
export type AllowInvoicingSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type AssignSnapshotUserApiResponse = /** status 200 Success */ void;
export type AssignSnapshotUserApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  assignSnapshotUserRequest: AssignSnapshotUserRequest;
};
export type CopySnapshotApiResponse = /** status 200 Success */ SnapshotModel;
export type CopySnapshotApiArg = {
  /** Request body */
  copySnapshotRequest: CopySnapshotRequest;
};
export type CreateSnapshotFromBaselineApiResponse =
  /** status 200 Success */ SnapshotModel;
export type CreateSnapshotFromBaselineApiArg = {
  /** Request body */
  createSnapshotFromBaselineRequest: CreateSnapshotFromBaselineRequest;
};
export type CreditSnapshotApiResponse = /** status 200 Success */ void;
export type CreditSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type DisallowInvoicingSnapshotApiResponse = /** status 200 Success */ void;
export type DisallowInvoicingSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type DismissSnapshotMessageApiResponse = /** status 200 Success */ void;
export type DismissSnapshotMessageApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Message identifier */
  messageId: string;
};
export type ExportSnapshotApiResponse = /** status 200 Success */ void;
export type ExportSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type GetSnapshotApiResponse = /** status 200 Snapshot */ SnapshotModel;
export type GetSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type GetSnapshotTotalsApiResponse =
  /** status 200 Snapshot totals */ SnapshotTotalsModel;
export type GetSnapshotTotalsApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type ListRelatedSnapshotsApiResponse =
  /** status 200 Related snapshots */ RelatedSnapshotModel[];
export type ListRelatedSnapshotsApiArg = {
  /** Baseline identifier */
  id: string;
};
export type ListSnapshotCollectionsApiResponse =
  /** status 200 Snapshot item groups */ SnapshotItemGroupModel[];
export type ListSnapshotCollectionsApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type ListSnapshotEformsApiResponse =
  /** status 200 Snapshot item groups */ SnapshotItemGroupModel[];
export type ListSnapshotEformsApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type ListSnapshotEventsApiResponse =
  /** status 200 Snapshot events */ SnapshotEventModel[];
export type ListSnapshotEventsApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type ListSnapshotStandardsApiResponse =
  /** status 200 Snapshot item groups */ SnapshotItemGroupModel[];
export type ListSnapshotStandardsApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type LockSnapshotApiResponse = /** status 200 Success */ void;
export type LockSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type RebaseSnapshotApiResponse = unknown;
export type RebaseSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  rebaseSnapshotRequest: RebaseSnapshotRequest;
};
export type RemoveSnapshotItemsApiResponse = /** status 200 Success */ void;
export type RemoveSnapshotItemsApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  removeSnapshotPricesRequest: RemoveSnapshotPricesRequest;
};
export type RevertSnapshotPricesApiResponse = /** status 200 Success */ void;
export type RevertSnapshotPricesApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  revertSnapshotPricesRequest: RevertSnapshotPricesRequest;
};
export type SetContactPersonApiResponse = /** status 200 Success */ void;
export type SetContactPersonApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  setContactPersonRequest: SetContactPersonRequest;
};
export type StartSnapshotApiResponse = /** status 200 Success */ void;
export type StartSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type SubmitSnapshotApiResponse = /** status 200 Success */ void;
export type SubmitSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type UnlockSnapshotApiResponse = /** status 200 Success */ void;
export type UnlockSnapshotApiArg = {
  /** Snapshot identifier */
  id: string;
};
export type UpdatePurchaseOrderNumberApiResponse = /** status 200 Success */ void;
export type UpdatePurchaseOrderNumberApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  updatePurchaseOrderNumberRequest: UpdatePurchaseOrderNumberRequest;
};
export type UpdateSnapshotPricesApiResponse = /** status 200 Success */ void;
export type UpdateSnapshotPricesApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  updateSnapshotPricesRequest: UpdateSnapshotPricesRequest;
};
export type ImportRequestForPriceItemsApiResponse = /** status 200 Success */ void;
export type ImportRequestForPriceItemsApiArg = {
  /** Form data */
  importRequestForPriceItemsRequest: ImportRequestForPriceItemsRequest;
};
export type ExportRequestForPriceItemsApiResponse = /** status 200 Success */ void;
export type ExportRequestForPriceItemsApiArg = {
  /** Snapshot identifier */
  snapshotId?: string;
};
export type UpdateSnapshotDiscountsApiResponse =
  /** status 200 Success */ SnapshotDiscountsModel;
export type UpdateSnapshotDiscountsApiArg = {
  /** Snapshot identifier */
  id: string;
  /** Request body */
  updateSnapshotDiscountsRequest: UpdateSnapshotDiscountsRequest;
};
export type ListCustomerContactPersonsApiResponse =
  /** status 200 List of customer contact persons */ CustomerContactPersonModel[];
export type ListCustomerContactPersonsApiArg = {
  /** Customer identifier */
  customerId: string;
};
export type BulkRequestBaselineUpdateApiResponse = /** status 200 Success */ void;
export type BulkRequestBaselineUpdateApiArg = {
  /** Request body */
  bulkRequestBaselineUpdateRequest: BulkRequestBaselineUpdateRequest;
};
export type ExportBaselinesApiResponse = /** status 200 Success */ void;
export type ExportBaselinesApiArg = {
  /** Invoice period start date */
  startDate?: string;
  /** Invoice period end date */
  endDate?: string;
};
export type ListBaselinesApiResponse =
  /** status 200 List of baselines */ BaselineListModel[];
export type ListBaselinesApiArg = {
  /** Invoice period start date */
  startDate?: string;
  /** Invoice period end date */
  endDate?: string;
};
export type RequestBaselineUpdateApiResponse = /** status 200 Success */ void;
export type RequestBaselineUpdateApiArg = {
  /** Baseline identifier */
  id: string;
};
export type UserModel = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};
export type SdValidationError = {
  identifier?: string;
  errorMessage?: string;
  errorCode?: string;
  severity?: Severity;
};
export type UpdateInvoicingMethodRequest = {
  invoicingMethod?: InvoicingMethod;
};
export type AssignSnapshotUserRequest = {
  userId?: string | null;
};
export type ContactPersonModel = {
  id: string;
  referenceId: string;
  firstName: string;
  lastName: string;
  email: string;
};
export type SnapshotMessageModel = {
  id: string;
  messageType: MessageType;
  messageSeverity: MessageSeverity;
  text: string;
  createdAt: string;
};
export type SnapshotModel = {
  id: string;
  baselineId: string;
  subscriptionId: string;
  description: string;
  customerId: string;
  customerName: string;
  serviceLevel?: string;
  currency: Currency;
  numberOfUsers: number;
  invoicePeriodStartDate: string;
  invoicePeriodEndDate: string;
  snapshotStatus: SnapshotStatus;
  isCurrent: boolean;
  hasRequestForPriceItems: boolean;
  invoicingMethod: InvoicingMethod;
  purchaseOrderNumber?: string;
  orderNumber?: string;
  orderUrl?: string;
  assignedUser?: UserModel;
  contactPerson?: ContactPersonModel;
  createdByUser: UserModel;
  createdAt: string;
  updatedAt?: string | null;
  messages: SnapshotMessageModel[];
};
export type CopySnapshotRequest = {
  baselineId: string;
  snapshotId: string;
};
export type CreateSnapshotFromBaselineRequest = {
  baselineId: string;
};
export type DiscountModel = {
  discountType: DiscountType;
  value: number;
};
export type SnapshotTotalsModel = {
  maintenanceFeeAmount: number;
  maintenanceFeeDiscount: DiscountModel;
  maintenanceFeeDiscountAmount: number;
  maintenanceFeeTotalAmount: number;
  standardsAmount: number;
  standardsDiscount: DiscountModel;
  standardsDiscountAmount: number;
  standardsTotalAmount: number;
  collectionsAmount: number;
  collectionsDiscount: DiscountModel;
  collectionsDiscountAmount: number;
  collectionsTotalAmount: number;
  eformsAmount: number;
  eformsDiscount: DiscountModel;
  eformsDiscountAmount: number;
  eformsTotalAmount: number;
  rfpTotalAmount: number;
  discountsTotalAmount: number;
  totalAmount: number;
};
export type RelatedSnapshotModel = {
  id: string;
  orderNumber?: string;
  snapshotStatus: SnapshotStatus;
  createdAt: string;
};
export type SnapshotItemModel = {
  id: string;
  name: string;
  priceGroup?: string;
  baseCostPrice: number;
  baseCurrency: BaseCurrency;
  salesPrice: number;
  updatedSalesPrice?: number | null;
  updatedAt: string;
};
export type SnapshotItemGroupModel = {
  id: string;
  name: string;
  requestForPrice: boolean;
  items: SnapshotItemModel[];
};
export type SnapshotEventModel = {
  id: string;
  snapshotEventType: SnapshotEventType;
  payload?: string;
  user: UserModel;
  createdAt: string;
};
export type RebaseSnapshotRequest = {
  baselineId: string;
  sourceSnapshotId?: string | null;
};
export type RemoveSnapshotPricesRequest = {
  itemIds?: string[];
};
export type RevertSnapshotPricesRequest = {
  itemIds?: string[];
};
export type SetContactPersonRequest = {
  contactPersonId?: string;
};
export type UpdatePurchaseOrderNumberRequest = {
  purchaseOrderNumber?: string;
};
export type PriceUpdateItem = {
  id?: string;
  updatedSalesPrice?: number;
};
export type UpdateSnapshotPricesRequest = {
  items?: PriceUpdateItem[];
};
export type ImportRequestForPriceItemsRequest = {
  snapshotId?: string | null;
  file?: Blob;
};
export type SnapshotDiscountsModel = {
  maintenanceFeeDiscount: DiscountModel;
  standardsDiscount: DiscountModel;
  collectionsDiscount: DiscountModel;
  eformsDiscount: DiscountModel;
  updatedAt?: string | null;
};
export type UpdateSnapshotDiscountsRequest = {
  maintenanceFeeDiscount: DiscountModel;
  standardsDiscount: DiscountModel;
  collectionsDiscount: DiscountModel;
  eformsDiscount: DiscountModel;
};
export type CustomerContactPersonModel = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};
export type BulkRequestBaselineUpdateRequest = {
  baselineIds?: string[];
};
export type BaselineListModel = {
  id: string;
  subscriptionId: string;
  description: string;
  customerId: string;
  customerName: string;
  invoicePeriodStartDate: string;
  invoicePeriodEndDate: string;
  hasRequestForPriceItems: boolean;
  createdAt: string;
  updatedAt: string;
  snapshotStatus: SnapshotStatus;
  purchaseOrderNumber?: string;
  assignedUser?: UserModel;
  currentSnapshotId?: string | null;
  snapshots: RelatedSnapshotModel[];
  messages: SnapshotMessageModel[];
};
export enum Severity {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}
export enum InvoicingMethod {
  Manual = 'Manual',
  Automatic = 'Automatic',
}
export enum Currency {
  Nok = 'NOK',
  Eur = 'EUR',
  Usd = 'USD',
  Chf = 'CHF',
}
export enum SnapshotStatus {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Locked = 'Locked',
  Submitted = 'Submitted',
  Invoiced = 'Invoiced',
  CreditNoteIssued = 'CreditNoteIssued',
  Credited = 'Credited',
  DoNotInvoice = 'DoNotInvoice',
}
export enum MessageType {
  General = 'General',
  Invoicing = 'Invoicing',
  Crediting = 'Crediting',
  BaselineUpdate = 'BaselineUpdate',
}
export enum MessageSeverity {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
}
export enum DiscountType {
  Percent = 'Percent',
  Amount = 'Amount',
}
export enum BaseCurrency {
  Nok = 'NOK',
  Eur = 'EUR',
  Usd = 'USD',
  Chf = 'CHF',
}
export enum SnapshotEventType {
  CreatedFromBaseline = 'CreatedFromBaseline',
  Started = 'Started',
  Locked = 'Locked',
  Unlocked = 'Unlocked',
  InvoicingDisallowed = 'InvoicingDisallowed',
  InvoicingAllowed = 'InvoicingAllowed',
  ItemsExported = 'ItemsExported',
  ItemsImported = 'ItemsImported',
  PricesUpdated = 'PricesUpdated',
  PricesReverted = 'PricesReverted',
  ItemsRemoved = 'ItemsRemoved',
  DiscountsUpdated = 'DiscountsUpdated',
  PurchaseOrderNumberUpdated = 'PurchaseOrderNumberUpdated',
  UserAssigned = 'UserAssigned',
  ContactPersonSet = 'ContactPersonSet',
  Exported = 'Exported',
  Submitted = 'Submitted',
  MarkedAsInvoiced = 'MarkedAsInvoiced',
  InvoicingError = 'InvoicingError',
  MessageDismissed = 'MessageDismissed',
  CreditNoteIssued = 'CreditNoteIssued',
  Credited = 'Credited',
  Copied = 'Copied',
  Rebased = 'Rebased',
  InvoicingMethodUpdated = 'InvoicingMethodUpdated',
  BaselineUpdateRequested = 'BaselineUpdateRequested',
  BaselineUpdated = 'BaselineUpdated',
}
export const {
  useListAssigneeUsersQuery,
  useLazyListAssigneeUsersQuery,
  useUpdateInvoicingMethodMutation,
  useAllowInvoicingSnapshotMutation,
  useAssignSnapshotUserMutation,
  useCopySnapshotMutation,
  useCreateSnapshotFromBaselineMutation,
  useCreditSnapshotMutation,
  useDisallowInvoicingSnapshotMutation,
  useDismissSnapshotMessageMutation,
  useExportSnapshotMutation,
  useGetSnapshotQuery,
  useLazyGetSnapshotQuery,
  useGetSnapshotTotalsQuery,
  useLazyGetSnapshotTotalsQuery,
  useListRelatedSnapshotsQuery,
  useLazyListRelatedSnapshotsQuery,
  useListSnapshotCollectionsQuery,
  useLazyListSnapshotCollectionsQuery,
  useListSnapshotEformsQuery,
  useLazyListSnapshotEformsQuery,
  useListSnapshotEventsQuery,
  useLazyListSnapshotEventsQuery,
  useListSnapshotStandardsQuery,
  useLazyListSnapshotStandardsQuery,
  useLockSnapshotMutation,
  useRebaseSnapshotMutation,
  useRemoveSnapshotItemsMutation,
  useRevertSnapshotPricesMutation,
  useSetContactPersonMutation,
  useStartSnapshotMutation,
  useSubmitSnapshotMutation,
  useUnlockSnapshotMutation,
  useUpdatePurchaseOrderNumberMutation,
  useUpdateSnapshotPricesMutation,
  useImportRequestForPriceItemsMutation,
  useExportRequestForPriceItemsMutation,
  useUpdateSnapshotDiscountsMutation,
  useListCustomerContactPersonsQuery,
  useLazyListCustomerContactPersonsQuery,
  useBulkRequestBaselineUpdateMutation,
  useExportBaselinesQuery,
  useLazyExportBaselinesQuery,
  useListBaselinesQuery,
  useLazyListBaselinesQuery,
  useRequestBaselineUpdateMutation,
} = injectedRtkApi;
