import { configureStore } from '@reduxjs/toolkit';
import { bffUserApi } from './services/bffUserApi';
import messageReducer from './slices/message';
import { api } from './services/api';
import { localApi } from './services/localApi';

const reducers = {
  [api.reducerPath]: api.reducer,
  [localApi.reducerPath]: localApi.reducer,
  [bffUserApi.reducerPath]: bffUserApi.reducer,
  message: messageReducer,
};

const middlewares = [api.middleware, localApi.middleware, bffUserApi.middleware];

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middlewares),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
