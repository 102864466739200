import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserClaim } from 'types/general';
import { bffUserUrl } from 'util/constants';

export const bffUserApi = createApi({
  reducerPath: 'bffUserApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBffUser: builder.query<UserClaim[], void>({
      query: () => ({
        url: bffUserUrl,
      }),
    }),
  }),
});

export const { useGetBffUserQuery } = bffUserApi;
