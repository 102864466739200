import i18n from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import {
  AvailableLanguages,
  fallbackLanguage,
  languageDetectionOptions,
  supportedLanguages,
} from './i18nConfig';
import translationsEn from 'translations/en.json';
import translationsNb from 'translations/nb.json';

export const translationResources = {
  [AvailableLanguages.en]: {
    translation: translationsEn,
  },
  [AvailableLanguages.nb]: {
    translation: translationsNb,
  },
} as const;

void i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: translationResources,
    detection: languageDetectionOptions,
    fallbackLng: fallbackLanguage,
    supportedLngs: supportedLanguages(),
    interpolation: {
      escapeValue: false,
    },
    returnNull: false,
  });

export default i18n;
