import styles from './Loader.module.css';
import Loading from '@standardsdigital/ui-components/Loading';

interface LoaderProps {
  'aria-label'?: string;
  position?: 'fixed' | 'absolute';
}

const Loader = ({
  'aria-label': ariaLabel = '',
  position = 'fixed',
}: LoaderProps) => {
  return (
    <Loading
      size="large"
      aria-label={ariaLabel}
      className={`${styles.loader} ${styles[position]}`}
    />
  );
};

export default Loader;
