import { GridEventListener, GridRowClassNameParams } from '@mui/x-data-grid-pro';
import { endOfYear, formatISO, getYear, startOfYear } from 'date-fns';
import { BaselineListModel, MessageSeverity } from 'redux/services/generatedApi';

export interface InvoicePeriod {
  startDate: string;
  endDate: string;
  label: string | number;
}

export const getInvoicePeriods = () => {
  const defaultInvoicePeriod = {
    startDate: formatISO(startOfYear(new Date())),
    endDate: formatISO(endOfYear(new Date())),
    label: getYear(new Date()),
  };

  try {
    const invoicePeriods = JSON.parse(
      import.meta.env.REACT_APP_INVOICE_PERIODS,
    ) as InvoicePeriod[];
    if (!Array.isArray(invoicePeriods) || !invoicePeriods.length) {
      return [defaultInvoicePeriod];
    }
    return invoicePeriods;
  } catch (error) {
    console.error(error);
    return [defaultInvoicePeriod];
  }
};

export const getInvoicePeriodDates = (invoicePeriod: InvoicePeriod) => {
  const { label, ...dates } = invoicePeriod;
  return dates;
};

const sharedButtonSelector = '.sd-button';

export const handleActionCellKeyDown: GridEventListener<'cellKeyDown'> = (
  params,
  event,
) => {
  // TODO: Space should not move focus to the last visible cell (SI-90).
  const matchingKeys = ['Enter', ' '];
  if (params.field === 'action' && matchingKeys.includes(event.key)) {
    (
      event.currentTarget.querySelector(sharedButtonSelector) as HTMLElement
    )?.click();
  }
};

export const errorStyle = (params: GridRowClassNameParams<BaselineListModel>) => {
  if (
    params.row.messages.length &&
    params.row.messages[0].messageSeverity === MessageSeverity.Error
  ) {
    return 'error-row';
  }
  return '';
};
