import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import ThemeProvider from '@standardsdigital/ui-components/ThemeProvider';
import Alert from '@standardsdigital/ui-components/Alert';
import Header from 'components/global/Header/Header';
import LanguagePath from 'components/util/LanguagePath/LanguagePath';
import LoginHandler from 'components/util/LoginHandler/LoginHandler';
import NotFoundPage from 'components/pages/NotFoundPage/NotFoundPage';
import { Suspense, lazy, useState } from 'react';
import {
  InvoicePeriod,
  getInvoicePeriods,
} from 'components/pages/OverviewPage/util';
import Loader from 'components/util/Loader/Loader';
import { useAppSelector } from 'redux/hooks';
import { selectMessage, showMessage } from 'redux/slices/message';
import { useDispatch } from 'react-redux';
import 'themes/baseTheme/styles/global-styles.css';
import 'themes/baseTheme/styles/mui-overrides.css';
import Favicon from 'components/util/Favicon/Favicon';
import { HelmetProvider } from 'react-helmet-async';
import styles from './App.module.css';
import { useTranslation } from 'react-i18next';

const OverviewPage = lazy(
  () => import('components/pages/OverviewPage/OverviewPage'),
);
const DetailsPage = lazy(() => import('components/pages/DetailsPage/DetailsPage'));
const EventLogPage = lazy(
  () => import('components/pages/EventLogPage/EventLogPage'),
);

export enum PageRoute {
  Home = '/',
  Details = 'details',
  EventLog = 'event-log',
  NotFound = 'not-found',
}

const invoicePeriods = getInvoicePeriods();
const lastPeriod = invoicePeriods[invoicePeriods.length - 1];

const App = () => {
  const { t } = useTranslation();

  const [selectedInvoicePeriod, setSelectedInvoicePeriod] =
    useState<InvoicePeriod>(lastPeriod);

  const dispatch = useDispatch();

  const alertMessage = useAppSelector(selectMessage);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Favicon />
        <ThemeProvider theme="sn" />
        {alertMessage.message && (
          <Alert
            variant={alertMessage.variant}
            message={alertMessage.message ?? ''}
            onClose={() => dispatch(showMessage({ message: null }))}
            sensitivity={alertMessage.sensitivity}
            accessibleLabel={t('general.notification')}
          />
        )}
        <Routes>
          <Route
            element={
              <div className={styles.layout}>
                <Header />
                <Outlet />
              </div>
            }
          >
            <Route path=":lang?/*" element={<LanguagePath />}>
              <Route
                path=""
                element={
                  <LoginHandler>
                    <Suspense fallback={<Loader />}>
                      <OverviewPage
                        selectedInvoicePeriod={selectedInvoicePeriod}
                        setSelectedInvoicePeriod={setSelectedInvoicePeriod}
                      />
                    </Suspense>
                  </LoginHandler>
                }
              />
              <Route
                path={`${PageRoute.Details}/:snapshotId`}
                element={
                  <LoginHandler>
                    <Suspense fallback={<Loader />}>
                      <DetailsPage />
                    </Suspense>
                  </LoginHandler>
                }
              />
              <Route
                path={`${PageRoute.EventLog}/:snapshotId`}
                element={
                  <LoginHandler>
                    <Suspense fallback={<Loader />}>
                      <EventLogPage />
                    </Suspense>
                  </LoginHandler>
                }
              />
              <Route path={PageRoute.NotFound} element={<NotFoundPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;
