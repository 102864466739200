import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import store from './redux/store';
import App from './components/App/App';
import './i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_PRO_LICENSE_KEY);

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
  );
};

if (import.meta.env.REACT_APP_ENABLE_MOCK_API === 'true' && import.meta.env.DEV) {
  import('./mocks/browser')
    .then((apiMock) =>
      apiMock.worker
        .start({
          onUnhandledRequest(request, print) {
            const url = new URL(request.url);
            const whiteList = [
              '/static',
              '/favicon',
              '/bff',
              '/main.',
              '/api/',
              'src_mocks_browser_ts.',
              '/src',
              '/node_modules',
              '/assets/',
            ];
            const shouldSuppressWarning = whiteList.some((pathStart) =>
              url.pathname.startsWith(pathStart),
            );
            if (shouldSuppressWarning) {
              return;
            }
            print.warning();
          },
        })
        .then(() => renderApp()),
    )
    .catch((error) => console.error(error));
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
