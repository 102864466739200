import { PageRoute } from 'components/App/App';
import { AvailableLanguages } from 'i18nConfig';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';

const LanguagePath = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (lang === i18n.language) {
      return;
    }
    if (!lang) {
      navigate(`${i18n.language}${pathname}`, { replace: true });
      return;
    }
    if (lang in AvailableLanguages) {
      void i18n.changeLanguage(lang);
      return;
    }
    navigate(`/${i18n.language}/${PageRoute.NotFound}`);
  }, [lang, i18n, navigate, pathname]);

  return <Outlet />;
};

export default LanguagePath;
