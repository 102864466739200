import { DetectorOptions } from 'i18next-browser-languagedetector';

export enum AvailableLanguages {
  en = 'en',
  nb = 'nb',
}

// If everything else fails, use Norwegian as default language.
const internalFallbackLanguageCode = AvailableLanguages.nb;

const fallbackLanguageCode: AvailableLanguages =
  (import.meta.env.REACT_APP_FALLBACK_LANGUAGE as AvailableLanguages) ||
  internalFallbackLanguageCode;

export const fallbackLanguage = fallbackLanguageCode;

const requestedLanguages: AvailableLanguages[] =
  import.meta.env.REACT_APP_UI_LANGUAGES?.split(',').map(
    (lng) => lng as AvailableLanguages,
  ) || [fallbackLanguageCode];

export const supportedLanguages = (): AvailableLanguages[] =>
  requestedLanguages.filter((language) => AvailableLanguages[language]);

export const languageDetectionOptions: DetectorOptions = {
  // Specify where the user's language should be detected from and order.
  order: ['path'],
  lookupFromPathIndex: 0,
  convertDetectedLanguage: (lang) =>
    lang in AvailableLanguages ? lang : fallbackLanguage,
};
