import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertProps } from '@standardsdigital/ui-components/Alert';
import type { RootState } from '../store';

interface Message {
  message: AlertProps['message'] | null;
  variant?: AlertProps['variant'];
  sensitivity?: AlertProps['sensitivity'];
}

const initialState: Message = { message: null };

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage(state, action: PayloadAction<Message>) {
      state.message = action.payload.message;
      state.variant = action.payload.variant;
      state.sensitivity = action.payload.sensitivity;
    },
  },
});

export const { showMessage } = messageSlice.actions;

export const selectMessage = (state: RootState) => state.message;

export default messageSlice.reducer;
