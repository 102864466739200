import { PropsWithChildren, useState } from 'react';
import { useGetBffUserQuery } from 'redux/services/bffUserApi';
import SilentLogin from '../SilentLogin/SilentLogin';
import NotLoggedInPage from 'components/pages/NotLoggedInPage/NotLoggedInPage';
import Loader from 'components/util/Loader/Loader';
import AccessDeniedPage from 'components/pages/AccessDeniedPage/AccessDeniedPage';
import { allowedUserRoles } from 'util/constants';

const LoginHandler = ({ children }: PropsWithChildren) => {
  const { data: claims, isFetching, isError, refetch } = useGetBffUserQuery();

  const hasMatchingRole = claims?.some(
    (claim) => claim.type === 'role' && allowedUserRoles.includes(claim.value),
  );

  const [isSilentLoginCompleted, setIsSilentLoginCompleted] = useState(false);

  const onSilentLoginCompleted = (isLoggedIn: boolean) => {
    if (isLoggedIn) {
      void refetch();
    }
    setIsSilentLoginCompleted(true);
  };

  if (claims) {
    return hasMatchingRole ? <>{children}</> : <AccessDeniedPage />;
  }

  if (isError && !isSilentLoginCompleted) {
    return (
      <>
        <Loader />
        <SilentLogin onCompleted={onSilentLoginCompleted} />
      </>
    );
  }

  if (isFetching || !isSilentLoginCompleted) {
    return <Loader />;
  }

  return <NotLoggedInPage />;
};

export default LoginHandler;
