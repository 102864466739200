import AppHeader from '@standardsdigital/ui-components/AppHeader';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetBffUserQuery } from 'redux/services/bffUserApi';
import { getActiveCustomer } from 'util/util';
import { AvailableLanguages } from 'i18nConfig';
import { Helmet } from 'react-helmet-async';
import { appName, logInUrl } from 'util/constants';
import { PageRoute } from 'components/App/App';
import styles from './Header.module.css';
import { useGetAppLinksQuery } from 'redux/services/localApi';

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const theme = getActiveCustomer();
  const [logoPath, setLogoPath] = useState('');
  const { data: claims } = useGetBffUserQuery();
  const logOutUrl = claims?.find((claim) => claim.type === 'bff:logout_url')?.value;
  const firstName = claims?.find((claim) => claim.type === 'given_name')?.value;

  const { data: appLinks } = useGetAppLinksQuery();

  useEffect(() => {
    import(`../../../themes/${theme}/assets/logo.svg`)
      .then(({ default: logo }) => setLogoPath(logo as string))
      .catch((error) => console.error(error));
  }, [theme]);

  const logoLink = (
    <Link to={PageRoute.Home} aria-label={t('general.home')}>
      <img src={logoPath} alt={`${theme.toUpperCase()} logo`} />
    </Link>
  );

  const userMenuProps = {
    'aria-label': t('general.userMenu'),
    logInUrl: logInUrl,
    logInText: t('authentication.logIn'),
    logOutUrl,
    logOutText: t('authentication.logOut'),
    firstName,
  };

  const getPath = (newLang: AvailableLanguages) => {
    const path = location.pathname;
    const detectedLanguage = path.substring(1, 3);
    if (!(detectedLanguage in AvailableLanguages)) {
      return `/${newLang}${path}`;
    }
    return `/${newLang}/${path.substring(4)}`;
  };

  const languageSelectorProps = {
    'aria-label': t('general.selectLanguage'),
    activeLanguage: {
      code: i18n.language,
      label: t(`languages.${i18n.language as AvailableLanguages}`),
    },
    languages: Object.values(AvailableLanguages).map((lang) => ({
      languageCode: lang,
      component: <Link to={getPath(lang)}>{t(`languages.${lang}`)}</Link>,
    })),
  };

  const appSelectorProps = {
    'aria-label': t('general.selectLanguage'),
    currentAppName: appName,
    appLinks: appLinks
      ? appLinks.map(({ title, url, id }) => ({ name: title, url, id }))
      : [],
  };

  return (
    <>
      <AppHeader
        logo={logoLink}
        appSelectorProps={appSelectorProps}
        userMenuProps={userMenuProps}
        languageSelectorProps={languageSelectorProps}
        className={styles.appHeader}
        hamburgerMenuButtonText={t('general.openMenu')}
        compactHeaderThreshold={720}
      />
      <Helmet>
        <html lang={i18n.language} />
      </Helmet>
    </>
  );
};

export default Header;
