import styles from './DashlineIcon.module.css';

const DashlineIcon: React.FC = () => (
  <svg
    className={styles.dashline}
    width="36"
    height="8"
    viewBox="0 0 36 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4H32"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeDasharray="0.1 8"
    />
  </svg>
);

export default DashlineIcon;
