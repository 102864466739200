import { useTranslation } from 'react-i18next';
import DashlineIcon from './DashlineIcon/DashlineIcon';
import Icon from '@standardsdigital/ui-components/Icon';
import LinkButton from '@standardsdigital/ui-components/LinkButton';
import { Link } from 'react-router-dom';
import { PageRoute } from 'components/App/App';
import styles from './NotFoundPage.module.css';
import { Helmet } from 'react-helmet-async';
import { appName } from 'util/constants';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('notFound.title')} | ${appName}`}</title>
      </Helmet>
      <main className={styles.notFoundPage}>
        <div className={styles.visual} aria-hidden>
          <Icon name="Link" className={styles.icon} />
          <DashlineIcon />
          <Icon name="FileX" className={styles.iconShake} />
          <DashlineIcon />
          <Icon name="Frown" className={styles.icon} />
        </div>

        <h1 className={`${styles.title} sd-heading-lg`}>{t('notFound.title')}</h1>
        <p className={styles.text}>{t('notFound.text')}</p>
        <LinkButton component={Link} to={PageRoute.Home} variant="primary">
          {t('notFound.action')}
        </LinkButton>
      </main>
    </>
  );
};

export default NotFoundPage;
