import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type AppLink = {
  id: string;
  url: string;
  title: string;
};

export const localApi = createApi({
  reducerPath: 'localApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
    baseUrl: '/local',
  }),
  endpoints: (builder) => ({
    getAppLinks: builder.query<AppLink[], void>({
      query: () => `app-links`,
    }),
  }),
});

export const { useGetAppLinksQuery } = localApi;
