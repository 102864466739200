import LinkButton from '@standardsdigital/ui-components/LinkButton';
import { useTranslation } from 'react-i18next';
import styles from './NotLoggedInPage.module.css';
import { appName, logInUrl } from 'util/constants';
import { Helmet } from 'react-helmet-async';

const NotLoggedInPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t('authentication.pleaseLogIn')} | ${appName}`}</title>
      </Helmet>
      <main className={styles.main}>
        <h1 className="sd-heading-lg">{t('authentication.pleaseLogIn')}</h1>
        <p className={styles.notLoggedInMessage}>
          {t('authentication.notLoggedInMessage')}
        </p>
        <LinkButton href={logInUrl} variant="primary">
          {t('authentication.logIn')}
        </LinkButton>
      </main>
    </>
  );
};

export default NotLoggedInPage;
