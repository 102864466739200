import { generatedApi } from './generatedApi';
import { Cache } from './emptyApi';
import { showMessage } from 'redux/slices/message';
import { saveAs } from 'file-saver';
import i18n from 'i18n';
import { getFileNameFromContentDisposition } from 'util/util';

export const api = generatedApi.enhanceEndpoints({
  endpoints: {
    listBaselines: {
      providesTags: [Cache.Baselines],
    },
    listSnapshotStandards: {
      providesTags: [Cache.Items],
    },
    listSnapshotCollections: {
      providesTags: [Cache.Items],
    },
    listSnapshotEforms: {
      providesTags: [Cache.Items],
    },
    getSnapshot: {
      providesTags: [Cache.Snapshot],
    },
    listSnapshotEvents: {
      providesTags: [Cache.EventLog],
    },
    getSnapshotTotals: {
      providesTags: [Cache.Totals],
    },
    listRelatedSnapshots: {
      providesTags: [Cache.RelatedSnapshots],
    },
    createSnapshotFromBaseline: {
      invalidatesTags: [Cache.RelatedSnapshots, Cache.Snapshot, Cache.Baselines],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.createSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.createSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    copySnapshot: {
      invalidatesTags: [Cache.RelatedSnapshots, Cache.Snapshot, Cache.Baselines],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.createSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.createSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    creditSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.creditSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.creditSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    submitSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.sendToInvoicingSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          let message = i18n.t('snapshot.sendToInvoicingError');
          if (
            // @ts-ignore - Error has to be of type "unknown".
            'error' in error &&
            // @ts-ignore - Error has to be of type "unknown".
            'data' in error.error &&
            Array.isArray(error.error.data) &&
            error.error.data.length > 0
          ) {
            message += `\n"${error.error.data[0] as string}."`;
          }
          dispatch(
            showMessage({
              message,
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    updateSnapshotDiscounts: {
      invalidatesTags: [Cache.Totals, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('totals.discountUpdateSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('totals.discountUpdateError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    importRequestForPriceItems: {
      invalidatesTags: [Cache.Snapshot, Cache.Items, Cache.Totals, Cache.EventLog],
      query: (queryArg) => {
        const formData = new FormData();
        formData.append(
          'file',
          queryArg.importRequestForPriceItemsRequest.file ?? '',
        );
        return {
          url: `/import`,
          method: 'POST',
          body: formData,
          headers: {
            accept: '*/*',
            'content-type': 'multipart/form-data',
          },
          responseHandler: async (response: Response) => {
            if (response.ok) {
              const blob: Blob = await response.blob();
              const filename = getFileNameFromContentDisposition(response.headers);
              saveAs(blob, filename);
            }
          },
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('import.importSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('import.importError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    exportRequestForPriceItems: {
      invalidatesTags: [Cache.EventLog],
      query: (queryArg) => {
        return {
          url: `/export`,
          method: 'POST',
          params: { snapshotId: queryArg.snapshotId },
          responseHandler: async (response: Response) => {
            if (response.ok) {
              const blob: Blob = await response.blob();
              const filename = getFileNameFromContentDisposition(response.headers);
              saveAs(blob, filename);
            }
          },
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('export.exportSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('export.exportError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    exportSnapshot: {
      invalidatesTags: [Cache.EventLog],
      query: (queryArg) => {
        return {
          url: `/snapshots/${queryArg.id}/export`,
          method: 'POST',
          responseHandler: async (response: Response) => {
            if (response.ok) {
              const blob: Blob = await response.blob();
              const filename = getFileNameFromContentDisposition(response.headers);
              saveAs(blob, filename);
            }
          },
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.exportSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.exportSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    exportBaselines: {
      query: (queryArg) => {
        return {
          url: `/baselines/export?startDate=${encodeURIComponent(
            queryArg.startDate ?? '',
          )}&endDate=${encodeURIComponent(queryArg.endDate ?? '')}`,
          method: 'GET',
          responseHandler: async (response: Response) => {
            if (response.ok) {
              const blob: Blob = await response.blob();
              const filename = getFileNameFromContentDisposition(response.headers);
              saveAs(blob, filename);
            }
          },
        };
      },
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('baseline.exportSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('baseline.exportFailure'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    updateSnapshotPrices: {
      invalidatesTags: [Cache.Items, Cache.Totals, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.updateSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.updateError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    revertSnapshotPrices: {
      invalidatesTags: [Cache.Items, Cache.Totals, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.updateSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.updateError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    removeSnapshotItems: {
      invalidatesTags: [Cache.Items, Cache.Totals, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.removeSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('sdoTable.removeError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    updatePurchaseOrderNumber: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.updatePurchaseOrderNumberSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.updatePurchaseOrderNumberError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    assignSnapshotUser: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.assignSnapshotUserSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.assignSnapshotUserError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    setContactPerson: {
      invalidatesTags: [Cache.Snapshot, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.setContactPersonSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.setContactPersonError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    disallowInvoicingSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.disallowInvoicingSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.disallowInvoicingError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    allowInvoicingSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.disallowInvoicingSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.disallowInvoicingError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    lockSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.lockSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.lockSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    unlockSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.unlockSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.unlockSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    startSnapshot: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.startSnapshotSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.startSnapshotError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    listCustomerContactPersons: {
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.fetchContactPersonsError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    rebaseSnapshot: {
      invalidatesTags: [
        Cache.Snapshot,
        Cache.Baselines,
        Cache.Items,
        Cache.Totals,
        Cache.EventLog,
      ],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.rebaseSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.rebaseError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    dismissSnapshotMessage: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('snapshot.dismissErrorSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('snapshot.dismissErrorError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    updateInvoicingMethod: {
      invalidatesTags: [Cache.Snapshot, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('subscription.updateInvoicingMethodSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('subscription.updateInvoicingMethodError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
    bulkRequestBaselineUpdate: {
      invalidatesTags: [Cache.Snapshot, Cache.Baselines, Cache.EventLog],
      onQueryStarted: async (_arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(
            showMessage({
              message: i18n.t('baseline.reimportSuccess'),
              variant: 'success',
              sensitivity: 'background',
            }),
          );
        } catch (error) {
          dispatch(
            showMessage({
              message: i18n.t('baseline.reimportError'),
              variant: 'danger',
              sensitivity: 'background',
            }),
          );
          console.error(error);
        }
      },
    },
  },
});
