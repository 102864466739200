import i18n from 'i18n';
import { dateLocales } from 'util/constants';
import { format, parseISO } from 'date-fns';

export const getActiveCustomer = (): string =>
  import.meta.env.REACT_APP_CUSTOMER_CODE || 'sn';

export const defaultDateFormat = 'yyyy-MM-dd';

const getCurrentLanguage = () =>
  i18n.language.toUpperCase() as keyof typeof dateLocales;

export const getDateFormat = (): string => {
  try {
    const formats = JSON.parse(import.meta.env.REACT_APP_DATE_FORMATS) as Record<
      string,
      string
    >;
    if (!formats[getCurrentLanguage()]) {
      return defaultDateFormat;
    }

    return formats[getCurrentLanguage()];
  } catch {
    return defaultDateFormat;
  }
};

export const formatPrice = (number: number): string => {
  const currentLanguage = getCurrentLanguage();

  return number.toLocaleString(currentLanguage, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatDate = (date: string | Date | undefined, dateFormat?: string) => {
  if (!date) {
    return '';
  }
  const isoDate = typeof date === 'string' ? parseISO(date) : date;
  return format(isoDate, dateFormat ? dateFormat : getDateFormat(), {
    locale: dateLocales[getCurrentLanguage()],
  });
};

export const getFileNameFromContentDisposition = (
  headers: Headers,
): string | undefined => {
  const contentDisposition = headers.get('Content-Disposition');
  return contentDisposition
    ? contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '')
    : undefined;
};
