import enGB from 'date-fns/locale/en-GB';
import nb from 'date-fns/locale/nb';

export const appName = 'Subscription Invoicing';

export const dateLocales = {
  EN: enGB,
  NO: nb,
};

export const defaultCurrency = 'NOK';

export const logInUrl = '/bff/login';
export const bffUserUrl = '/bff/user';
export const silentLoginUrl = '/bff/silent-login';
export const allowedUserRoles = ['Administrators'];

export const purchaseOrderNumberMaxLength = 35;
